<template>
  <div>
    <section class="aside-info">
      <div class="aside-logo">
        <!-- @Q@ 目前api還沒有圖象資料 -->
        <!-- <img src="" /> -->
        <div style="font-size:72px;text-align:center;line-height:110px;color:#c0c4cc">{{avatarCoverText}}</div>
        <el-button
          size="mini"
          circle
          @click="toggleCollection">
          <font-awesome-icon
            :icon="[ 'fas', 'heart' ]"
            :class="heartClass"/>
        </el-button>
      </div>
      <div class="aside-name">{{basic.company_name}}</div>
      <!-- 英文名稱顯示順序：商工登記→國貿局 -->
      <div class="aside-sub-name">{{basic.company_name_en || basic.company_name_en_MOEA}}</div>
      <div class="aside-tags">
        <el-tag
          v-for="companyTag in companyOperatingTags"
          :key="companyTag.text"
          :type="companyTag.type"
          size="mini">
          {{companyTag.text}}
        </el-tag>
      </div>
      <div class="aside-time">更新時間：{{basic.date_approved_latest}}</div>
    </section>
    <section>
      <ul class="list list--with-icon">
        <li class="item">
          <el-tooltip class="item" effect="dark" content="官方電話" placement="right">
            <font-awesome-icon :icon="[ 'fas', 'phone' ]" />
          </el-tooltip>
          <!-- <div
            class="item__box"
            :key="item.value"
            v-for="item in basic.phone">
            {{item.value}}
          </div> -->
          <div class="item__box">
            {{companyPhone}}
          </div>
        </li>
        <li class="item">
          <el-tooltip class="item" effect="dark" content="官方電子郵件" placement="right">
            <font-awesome-icon :icon="[ 'fas', 'envelope' ]" />
          </el-tooltip>
          <div v-if="basic.email&&basic.email.length">
            <!-- <div
              class="item__box"
              :key="item"
              v-for="item in basic.email">
              {{item}}
            </div> -->
            <div class="item__box">
            {{companyEmail}}
          </div>
          </div>
          <div v-else>
            <div class="item__box">
              -
            </div>
          </div>
        </li>
        <!-- @Q@ 地圖暫先拿掉 -->
        <!-- <li class="item">
          <el-tooltip class="item" effect="dark" content="稅藉地址" placement="right">
            <font-awesome-icon :icon="[ 'fas', 'map-marker-alt' ]" />
          </el-tooltip>
          <div class="item__box">
            <div class="map" style="height:167px">
              <Map :markers="mapMarkers">
              </Map>
            </div>
            <a href="#" target="_blank">附近分行</a>
          </div>
        </li> -->
        <li class="item">
          <el-tooltip class="item" effect="dark" content="官方網站" placement="right">
            <font-awesome-icon :icon="[ 'fas', 'globe' ]" />
          </el-tooltip>
          <a :href="companyUrlHref" target="_blank">{{companyUrl}}</a>
        </li>
        <li class="item">
          <el-tooltip class="item" effect="dark" content="地址" placement="right">
            <font-awesome-icon :icon="[ 'fas', 'map-marker-alt' ]" />
          </el-tooltip>
          {{basic.company_address}}
          <br>
          <a href="#" target="_blank" style="position:relative;left:4px">
            <router-link
              target="_blank"
              :to="returnCompanyAddressLinkTo(basic.uniID)">
              附近分行
            </router-link>
          </a>
        </li>
        <li class="item">
          <el-tooltip class="item" effect="dark" content="英文地址" placement="right">
            <font-awesome-icon :icon="[ 'fas', 'map-marker-alt' ]" />
          </el-tooltip>
          <!-- {{address_en_full}} -->
           {{ basic.company_address_translated_in_english }}
          <br>
        </li>        
        <li class="item">
          <!-- 登記現況 -->
          <el-tooltip class="item" effect="dark" :content="mapBasicColumnName(basic.data_type, 'company_state')" placement="right">
            <font-awesome-icon :icon="[ 'fas', 'registered' ]" />
          </el-tooltip>
          {{basic.company_state}}
        </li>
        <li class="item">
          <el-tooltip class="item" effect="dark" content="員工人數" placement="right">
            <font-awesome-icon :icon="[ 'fas', 'users' ]" />
          </el-tooltip>
          {{(basic.jobs && basic.jobs.summary) ? basic.jobs.summary.employee_count : null | emptyFormat | numberWithComma}}
        </li>
        <li class="item">
          <el-tooltip class="item" effect="dark" content="所營事業資料" placement="right">
            <font-awesome-icon :icon="[ 'far', 'file' ]" />
          </el-tooltip>
          <ul v-if="etaxMatters.length">
            <li
              :key="item"
              v-for="item in etaxMatters">
              {{item}}
            </li>
          </ul>
          <ul v-else>
            <li>-</li>
          </ul>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as apis from '@/apis/index.ts'
import utils from '@/utils/utils.js'
import { mapBasicColumnName } from '@/utils/mapColumnName.js'
// import Map from '@/components/Map/Map.vue'

export default {
  props: {
    'basic': {
      default () {
        return {}
      }
    }
  },
  components: {
    // Map
  },
  data () {
    return {
      isCollection: false,
      // companyTags: [
      //   { text: '營業中', type: 'success' },
      //   { text: '非營業中', type: 'danger' },
      //   { text: '1460', type: '' },
      //   { text: '上市', type: '' }
      // ],
      // address_en_full: ''
    }
  },
  computed: {
    ...mapGetters([
      'getterUserEmail'
    ]),
    ...mapGetters('companyDetail', [
      'getterIsCompanyPublic' // 為公開發行公司
    ]),
    // 無公司圖象時的覆蓋文字
    avatarCoverText () {
      let text = ''
      if (this.basic.company_name) {
        text = this.basic.company_name.slice(0, 1)
      }
      return text
    },
    companyPhone () {
      if (this.basic.multi && this.basic.multi.phone && this.basic.multi.phone.length) {
        return this.basic.multi.phone[0].value || '-'
      } else {
        return '-'
      }
    },
    companyEmail () {
      if (this.basic.multi && this.basic.multi.email && this.basic.multi.email.length) {
        return this.basic.multi.email[0].value || '-'
      } else {
        return '-'
      }
    },
    companyUrl () {
      if (this.basic.multi && this.basic.multi.url && this.basic.multi.url.length) {
        return this.basic.multi.url[0].value || '-'
      } else {
        return '-'
      }
    },
    companyUrlHref () {
      const companyUrl = this.companyUrl
      if (companyUrl.substr(0, 7) === 'http://' || companyUrl.substr(0, 8) === 'https://') {
        return companyUrl
      }
      return `http://${companyUrl}`
    },
    // companyEmployeeCount () {
    //   if (this.basic.jobs && this.basic.jobs.summary && this.basic.jobs.summary.employee_count >= 0) {
    //     return utils.formatCommaNumber(this.basic.jobs.summary.employee_count)
    //   } else {
    //     return '-'
    //   }
    // },
    companyOperatingTags () {
      let tags = []
      if (this.basic.company_operate === '營業中') {
        tags.push({ text: '營業中', type: 'success' })
      } else {
        tags.push({ text: '非營業中', type: 'danger' })
      }
      // 如果有公開發行資料的話
      if (this.getterIsCompanyPublic) {
        if (this.basic.publics.public_issue) {
          tags.push({ text: this.basic.publics.public_issue, type: 'info'})
        }
        if (this.basic.publics.stock_code) {
          tags.push({ text: this.basic.publics.stock_code, type: 'info'})
        }
      }
      return tags
    },
    etaxMatters () {
      if (this.basic.etax_matters_tideup && this.basic.etax_matters_tideup.length) {
        return this.basic.etax_matters_tideup.map(d => `${d.title}(${d.code})`)
      } else {
        return []
      }
    },
    mapMarkers () {
      // @Q@ 無資料先寫死
      return [{"lat":"22.6421775818","lng":"120.3031005859","text":"測試座標"},{"lat":"25.0407668","lng":"121.5396627","text":"台北101"},{"lat":"25.0482773","lng":"121.5150377","text":"台北火車站"}]
    },
    heartClass () {
      if (this.isCollection == true) {
        return 'color-danger'
      } else {
        return 'color-gray'
      }
    }
  },
  methods: {
    mapBasicColumnName,
    returnCompanyAddressLinkTo (uniID) {
      return {
        name: 'map',
        query: {
          uniID,
          nearestBranch: true
        }
      }
    },
    toggleCollection () {
      this.isCollection = !this.isCollection
      if (this.isCollection === true) {
        apis.collectionAdd({
          email: this.getterUserEmail,
          company_uniID: this.basic.uniID,
          company_name: this.basic.company_name,
          note: ''
        })
      } else {
        apis.collectionsDelete({
          email: this.getterUserEmail,
          companys: [this.basic.uniID]
        })
      }
    },
    
  },
  watch: {
    'basic': {
      handler (data) {
        if (data.uniID && this.getterUserEmail) {
          apis.isCollection({
            email: this.getterUserEmail,
            company: data.uniID
          }).then(d => {
            this.isCollection = d.payload || false
          })
        }
      },
      immediate: true
    },
    'basic.company_address': {
      async handler (d) {
        if (!d) {
          return
        }
        // const result = await apis.addressTranslate({
        //   address_tw: d
        // })
        // if (!result.payload || !result.payload.address_en_full) {
        //   return
        // }
        // this.address_en_full = result.payload.address_en_full
      },
      immediate: true
    }
  }
}
</script>
